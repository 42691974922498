import {
	DataAction,
	Payload,
	StateRepository
} from '@angular-ru/ngxs/decorators'
import { Selector, State } from '@ngxs/store'
import { Injectable } from '@angular/core'
import { PreferenceInterface } from '../../shared/model/preference.model'
import { NgxsDataRepository } from '@angular-ru/ngxs/repositories'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment'
import { registerLocaleData } from '@angular/common'
import { DepartmentDTO } from '../../shared/model/permission.model'
import { isMobile } from '../../core/helpers/functions'
import { DeviceDetectorService } from 'ngx-device-detector'
import {
	isForcedResolution,
	RESOLUTION_MODE_KEY
} from '../../core/helpers/is-forced-resolutions'

export const preferenceFeatureName = 'preference'

type ResolutionMode = 'tablet' | 'desktop'

@StateRepository()
@State<PreferenceInterface>({
	name: preferenceFeatureName,
	defaults: {
		departmentId: null,
		isCollapseMenu: true,
		soundNotifications: null,
		notification: null,
		lg: null,
		mfaRequired: false,
		needChooseDepartment: true,
		screenSaver: false,
		sessionExpire: false,
		department: null,
		secondaryDevicesIds: null,
		deviceId: null,
		absenceOfMeasurementsHintOpened: true,
		hasVirtualKeyboard: false,
		isIncorrectTimeSystem: false,
		pccLoginWithCNAUserWasPerformed: false
	}
})
@Injectable()
export class PreferenceState extends NgxsDataRepository<PreferenceInterface> {
	private readonly userAgent =
		window.navigator.userAgent ||
		navigator.userAgent ||
		navigator.vendor ||
		(window as any)?.opera

	constructor(
		private translate: TranslateService,
		private deviceDetectorService: DeviceDetectorService
	) {
		super()
	}

	// public readonly isMobile = !!(!this.deviceDetectorService.isDesktop(this.userAgent) && isMobile.any()) || (!!this.userAgent.match(/Version\/[\d\.]+.*Safari/) && isMobile.any());

	get isMobile() {
		const urlParams = new URLSearchParams(window.location.search)
		if (urlParams) {
			const modeParam = urlParams.get(RESOLUTION_MODE_KEY)
			if (modeParam) {
				if (modeParam === 'remove') {
					localStorage.removeItem(RESOLUTION_MODE_KEY)
				} else {
					localStorage.setItem(RESOLUTION_MODE_KEY, modeParam)
				}
			}
		}
		const resolutionMode = localStorage.getItem(RESOLUTION_MODE_KEY)
		if (resolutionMode) {
			if (resolutionMode === 'tablet') {
				return true
			}
			if (resolutionMode === 'desktop') {
				return false
			}
		}
		return (
			!!(
				!this.deviceDetectorService.isDesktop(this.userAgent) && isMobile.any()
			) ||
			(!!this.userAgent.match(/Version\/[\d\.]+.*Safari/) && isMobile.any())
		)
	}

	@Selector()
	public static hasVirtualKeyboard(state: PreferenceInterface): boolean {
		return state.hasVirtualKeyboard
	}

	@Selector()
	public static notification(state: PreferenceInterface): boolean | null {
		return state.notification
	}

	@Selector()
	public static incorrectTimeSystem(state: PreferenceInterface): boolean {
		return state.isIncorrectTimeSystem
	}

	@Selector()
	public static screenSaver(state: PreferenceInterface): boolean {
		return state.screenSaver
	}

	@Selector()
	public static preferenceDepartment(
		state: PreferenceInterface
	): DepartmentDTO | null {
		return state.department
	}

	@Selector()
	public static preferenceDepartmentId(
		state: PreferenceInterface
	): string | null {
		return state.departmentId
	}

	@Selector()
	public static preferenceIsCollapseMenu(state: PreferenceInterface): boolean {
		return state.isCollapseMenu
	}

	@Selector()
	public static sessionExpire(state: PreferenceInterface): boolean {
		return state.sessionExpire
	}

	@Selector()
	public static preferenceSoundNotifications(
		state: PreferenceInterface
	): boolean | null {
		return state.soundNotifications
	}

	@Selector()
	public static language(state: PreferenceInterface): string | null {
		return state.lg
	}

	@Selector()
	public static needChooseDepartment(state: PreferenceInterface): boolean {
		return state.needChooseDepartment
	}

	@Selector()
	public static isMfaRequired(state: PreferenceInterface): boolean {
		return state.mfaRequired
	}

	@Selector()
	public static currentUserDeviceId(state: PreferenceInterface): string | null {
		return state.deviceId
	}

	@Selector()
	public static secondaryDevicesIds(
		state: PreferenceInterface
	): string[] | null {
		return state.secondaryDevicesIds
	}

	@Selector()
	public static absenceOfMeasurementsHintOpened(
		state: PreferenceInterface
	): boolean {
		return state.absenceOfMeasurementsHintOpened
	}

	@Selector()
	public static isForcedResolution(): boolean | undefined {
		return isForcedResolution()
	}

	@Selector()
	static pccLoginWasPerformedWithCNAUser(state: PreferenceInterface): boolean {
		return state.pccLoginWithCNAUserWasPerformed
	}

	public isMfaRequired(): boolean {
		return this.snapshot.mfaRequired
	}

	public needChooseDepartment(): boolean {
		return this.snapshot.needChooseDepartment
	}

	@DataAction()
	public setScreenSever(@Payload('screenSaver') screenSaver: boolean) {
		this.ctx.patchState({
			screenSaver
		})
	}

	@DataAction()
	public setIncorrectTimeSystem(
		@Payload('isIncorrectTimeSystem') isIncorrectTimeSystem: boolean
	) {
		this.ctx.patchState({
			isIncorrectTimeSystem: false
		})
	}

	@DataAction()
	public setSessionExpire(@Payload('sessionExpire') sessionExpire: boolean) {
		this.ctx.patchState({
			sessionExpire
		})
	}

	@DataAction()
	public setPccLoginWasPerformedWithCNAUser(
		@Payload('pccLoginWithCNAUserWasPerformed')
		pccLoginWithCNAUserWasPerformed: boolean
	) {
		this.ctx.patchState({
			pccLoginWithCNAUserWasPerformed: pccLoginWithCNAUserWasPerformed
		})
	}

	@DataAction()
	setPreferenceDepartmentId(@Payload('id') id: string): void {
		if (id === 'all') this.ctx.patchState({ departmentId: null })
		else this.ctx.patchState({ departmentId: id })
	}

	@DataAction()
	setPreferenceDepartment(
		@Payload('department') department: DepartmentDTO | null
	): void {
		if (!department) this.ctx.patchState({ department: null })
		else this.ctx.patchState({ department })
	}

	@DataAction()
	setPreferenceIsCollapseMenu(
		@Payload('isCollapseMenu') isCollapseMenu: boolean
	): void {
		this.ctx.patchState({ isCollapseMenu })
	}

	@DataAction()
	setNotification(@Payload('notification') notification: boolean | null): void {
		this.ctx.patchState({ notification })
	}

	@DataAction()
	setNeedChooseDepartment(
		@Payload('needChooseDepartment') needChooseDepartment: boolean
	): void {
		this.ctx.patchState({ needChooseDepartment })
	}

	@DataAction()
	setCurrentDeviceId(@Payload('deviceId') deviceId: string | null): void {
		this.ctx.patchState({ deviceId })
	}

	@DataAction()
	setSecondaryDevicesIds(
		@Payload('deviceIds') secondaryDevicesIds: string[] | null
	): void {
		this.ctx.patchState({ secondaryDevicesIds })
	}

	@DataAction()
	setMfaRequired(@Payload('mfaRequired') mfaRequired: boolean): void {
		this.ctx.patchState({ mfaRequired: !mfaRequired ? false : true })
	}

	@DataAction()
	setVirtualKeyboardPresence(): void {
		this.ctx.patchState({ hasVirtualKeyboard: true })
	}

	@DataAction()
	setAbsenceOfMeasurementsHintOpened(
		@Payload('absenceOfMeasurementsHintOpened')
		absenceOfMeasurementsHintOpened: boolean
	): void {
		this.ctx.patchState({ absenceOfMeasurementsHintOpened })
	}

	@DataAction()
	setPreferenceSoundNotifications(
		@Payload('soundNotifications') soundNotifications: boolean
	): void {
		this.ctx.patchState({ soundNotifications })
	}

	@DataAction()
	setLanguage(@Payload('lg') lg: string): void {
		this.translate.use(lg)
		moment.locale(lg)
		registerLocaleData(lg)
		this.ctx.patchState({ lg })
	}
}
